<template>
  <div class="page-add-or-edit-asset">
    <b-card>
      <div class="title-page-item">
        <h4>{{isUpdate ? 'Cập nhật' : 'Tạo mới'}} vật dụng</h4>
      </div>
      <validation-observer ref="modelItem">
        <div class="main-content-page">
          <div class="form-item">
            <b-row>
              <b-col lg="3" md="3" sm="3" xs="12">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="departmentId" rules="required">
                    <label class="font-weight-bold font-size-label-custom">Phòng ban <span class="text-danger">(*)</span> </label>
                    <v-select v-model="idDepartment" :options="listDepartment" label="organizationBranchName" :reduce="(department) => department.id"></v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="3" xs="12">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="nameDevice" rules="required">
                    <label class="font-weight-bold font-size-label-custom">Tên vật dụng <span class="text-danger">(*)</span></label>
                    <b-form-input v-model="modelItem.type" placeholder="Nhập tên vật dụng"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="3" xs="12">
                <b-form-group>
                  <label class="font-weight-bold font-size-label-custom">Nhà cung cấp</label>
                  <b-form-input v-model="modelItem.supplier" placeholder="Nhập tên nhà cung cấp"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="3" xs="12">
                <b-form-group>
                  <label class="font-weight-bold font-size-label-custom">Hãng</label>
                  <b-form-input v-model="modelItem.brand" placeholder="Nhập tên hãng"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="form-item">
            <b-row>
              <b-col lg="3" md="3" xs="12">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="codeDevice" rules="required">
                    <label class="font-weight-bold font-size-label-custom">Mã <span class="text-danger">(*)</span></label>
                    <b-form-input v-model="modelItem.model" placeholder="Nhập mã"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" xs="12">
                <b-form-group v-if="!isUpdate">
                  <validation-provider #default="{ errors }" name="totalQuantityDevice" rules="required|integer">
                    <label class="font-weight-bold font-size-label-custom">Số lượng <span class="text-danger">(*)</span></label>
                    <b-form-input v-model="modelItem.totalQuantity" placeholder="Nhập serial"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="isUpdate">
                    <label class="font-weight-bold font-size-label-custom">Số lượng <span class="text-danger">(*)</span></label>
                    <b-form-input readonly v-model="modelItem.totalQuantity" placeholder="Nhập serial"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" xs="12">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="dateDevice" rules="required">
                    <label class="font-weight-bold font-size-label-custom">Ngày nhập <span class="text-danger">(*)</span></label>
                    <flat-pickr v-model="modelItem.importDate"
                                class="form-control"
                                :config="configFlatpickr"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="form-item">
            <b-row>
              <b-col lg="12" md="12" xs="12">
                <b-form-group>
                  <label class="font-weight-bold font-size-label-custom">Thông tin thêm</label>
                  <b-form-textarea v-model="modelItem.metadata"></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="acction-add-or-edit">
            <b-button v-if="!isUpdate" class="mr-1" @click="addDevice"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
            >
              <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
              />
              <span class="align-middle">Tạo mới</span>
            </b-button>
            <b-button v-if="isUpdate" class="mr-1" @click="updateDevice"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
            >
              <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
              />
              <span class="align-middle">Cập nhật</span>
            </b-button>
            <b-button :to="{ name: 'manager-item'}"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-danger"
            >
              <feather-icon
                  icon="CornerDownLeftIcon"
                  class="mr-50"
              />
              <span class="align-middle">Quay lại</span>
            </b-button>
          </div>
        </div>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BButton,
  VBTooltip, BFormGroup, BRow, BCol, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'

export default {
  components: {
    BCard,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    flatPickr,
    BButton,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isUpdate: false,
      listDepartment: [],
      idDepartment: null,
      nameDevice: '',
      idDevice: null,
      required,
      modelItem: {
        status: 0,
        totalQuantity: 0,
        importDate: '',
        type: '',
        supplier: '',
        brand: '',
        metadata: '',
        deviceType: 1,
        organizationBranchId: null,
      },
      configFlatpickr: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
      },
    }
  },
  created() {
    const parameters = this.$route.query
    this.nameDevice = parameters.name
    if (parameters.id) {
      this.isUpdate = true
      this.idDevice = parameters.id
      this.getDetailDevice()
    }
    this.getListDepartment()
  },
  methods: {
    getListDepartment() {
      this.$crm.get('organization-branch-school/find-all-department').then(res => {
        this.listDepartment = res.data
      })
    },
    getDetailDevice() {
      this.$crm.post(`device/find-by-id/${this.idDevice}`).then(res => {
        this.modelItem = res.data
        this.idDepartment = this.modelItem.organizationBranchId
      })
    },
    updateDevice() {
      this.$refs.modelItem.validate().then(success => {
        if (success) {
          this.modelItem.organizationBranchId = this.idDepartment
          this.$crm.post(`device/update/${this.idDevice}`, this.modelItem).then(res => {
            this.modelItem = res.data
            this.idDepartment = this.modelItem.organizationBranchId
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: `Cập nhật thành công vật dụng ${this.nameDevice}`,
              },
            })
            this.$router.push({
              name: 'manager-item',
            })
          })
        }
      })
    },
    addDevice() {
      this.$refs.modelItem.validate().then(success => {
        if (success) {
          this.$crm.post(`device/create/${this.idDepartment}`, this.modelItem).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Tạo mới vật dụng thành công',
              },
            })
            this.$router.push({
              name: 'manager-item',
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import '../../@core/scss/custom/add-or-edit-item';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
